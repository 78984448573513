import { PageProps } from "gatsby";
import Contact from "../../components/Contact";
import withPages from "../../hoc/withPages";

function IndexPage(props: PageProps) {
  return withPages(Contact)({
    title: "ติดต่อทีม Sales",
    description:
      "ปรึกษาผู้เชี่ยวชาญ deeple AI Chatbot ให้คุณมั่นใจว่าแชทบอทและระบบจัดการร้านค้าเราตอบโจทย์ธุรกิจของคุณได้จริง",
    ...props,
  });
}

export default IndexPage;
